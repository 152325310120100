@import "src/app/shared/styles/variables";

// material auto generated
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// custom global styles
.mat-menu-panel {
  max-height: 350px !important;
}

.navbar-container {
  background-color: #222222;
  color: white;
  height: $navbar-height;
  z-index: 1;
  width: 100%;
}

.custom-snackbar-error {
  background-color: #f44336;
  color: white;
}

.custom-snackbar-warning {
  background-color: #ff9800;
  color: white;
}

.blur {
  filter: blur(5px);
}